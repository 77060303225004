import {
  add,
  addMinutes,
  differenceInSeconds,
  endOfMinute,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  sub,
} from 'date-fns';
import { useEffect, useMemo } from 'react';

import { chart_resolutions, getBestResolution, getTime, startOfTime } from '../../../utils/chart';
import formatCubeQuery, { Granularity } from '../../../utils/formatCubeQuery';
import { hasSeconds, roundUpHour } from '../../../utils/time';
import { useCubeQueryLocalRawData } from '../../hooks/useCubeQueryLocalRawData';
import { DateRange } from '../../scenes/DashboardScene/Metrics/MetricDatePicker';
import { ChartDatapoint } from '../Chart/Chart';
import {
  metric_definitions,
  metric_time_units,
  MetricName,
  MetricOptions,
  MetricType,
  TimeUnit,
} from './metric-definitions';
import { TableData } from './MetricTable';

export type MetricChartType = 'chart:line' | 'chart:bar';
export type MetricDisplayType = 'card' | 'table' | MetricChartType;

export interface ChartData {
  data: ChartDatapoint[];
  total: number;
  highest: number;
  average: number;
  time_unit_configs: TimeUnit;
}

export type MetricData = {
  data: Record<string, ChartData> | ChartData | TableData[] | number | string | null;
  refetch: () => void;
};

export interface MetricConfigs {
  options?: MetricOptions;
  time_unit?: keyof typeof metric_time_units;
  date_range?: DateRange;
  force_rate_as?: 'minute' | 'second';
}

const RATE_MINUTE_THRESHOLD = 2;

const getDateFormat = (start_date: Date, end_date: Date, granularity: Granularity) => {
  let base = 'h:mm';

  // const has_minutes = hasMinutes(start_date) || hasMinutes(end_date);
  const has_seconds = hasSeconds(start_date) || hasSeconds(end_date) || granularity === 'second';

  if (has_seconds) {
    base = `${base}:ss`;
  }

  if (!isSameDay(start_date, end_date)) {
    base = `eee d, ${base}`;
  }

  if (!isSameMonth(start_date, end_date)) {
    base.replace('eee', 'MMM');
  }

  return `${base} a`;
};

export const formatDateRange = (
  date_range?: DateRange,
  time_unit?: keyof typeof metric_time_units,
) => {
  if (time_unit) {
    const configs = metric_time_units[time_unit];

    const now = add(endOfMinute(new Date()), { seconds: 1 });

    const minutes = now.getMinutes();
    const minutes_to_add_for_padding =
      minutes % configs.sampling.value === 0
        ? 0
        : minutes - (minutes % configs.sampling.value) + configs.sampling.value - minutes;
    const end_time = add(now, { minutes: minutes_to_add_for_padding });

    const start_date = sub(end_time, { [configs.unit]: configs.value });
    const end_date = end_time;

    const period_in_sample_granularity = configs.value * 60;
    const samples = period_in_sample_granularity / configs.sampling.value;

    return { start_date, end_date, configs: metric_time_units[time_unit], samples };
  }

  let start_date = new Date(date_range!.min!);
  let end_date = new Date(date_range!.max!);

  const diff = differenceInSeconds(end_date, start_date);

  let chart_resolution = getBestResolution(diff, 60, chart_resolutions).best;

  // if the granularity is hour, always round up so the time is greater then the time inputted
  if (chart_resolution.granularity === 'hour') {
    start_date = roundUpHour(start_date);
    end_date = roundUpHour(end_date);
    const diff = differenceInSeconds(end_date, start_date);
    chart_resolution = getBestResolution(diff, 60, chart_resolutions).best;
  }

  const now = new Date();
  const to_sub = getTime[chart_resolution.granularity](now) % chart_resolution.unit;
  const whatever = sub(now, { [`${chart_resolution.granularity}s`]: to_sub });

  const max_end_date = add(whatever, {
    [`${chart_resolution.granularity}s`]: chart_resolution.unit,
  });

  if (isBefore(max_end_date, end_date)) {
    end_date = max_end_date;
  }

  if (chart_resolution.granularity !== 'second') {
    end_date = add(startOfTime[chart_resolution.granularity](end_date), {
      [`${chart_resolution.granularity}s`]:
        (getTime[chart_resolution.granularity](end_date) * chart_resolution.unit) /
        chart_resolution.unit,
    });
    start_date = sub(end_date, {
      [`${chart_resolution.granularity}s`]: chart_resolution.steps * chart_resolution.unit,
    });
  }

  const configs: TimeUnit = {
    value: chart_resolution.unit,
    unit: `${chart_resolution.granularity}s`,
    sampling: {
      value: chart_resolution.unit,
      granularity: chart_resolution.granularity,
      date_format: getDateFormat(start_date, end_date, chart_resolution.granularity),
      priority_display: [
        getTime[chart_resolution.granularity],
        [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      ],
    },
  };

  return { start_date, end_date, configs, samples: chart_resolution.steps };
};

const formatChartRawData = (
  obj,
  end_date,
  time_unit_configs,
  samples,
  values,
  query,
  index: number,
  metric_type: MetricType,
) => {
  const step_start = sub(end_date, {
    [`${time_unit_configs.sampling.granularity}s`]:
      time_unit_configs.sampling.value * (samples - index),
  });

  const step_end = add(step_start, {
    [`${time_unit_configs.sampling.granularity}s`]: time_unit_configs.sampling.value,
  });

  const time_frame_values = values.filter(
    (d) =>
      addMinutes(new Date(d[query.timeDimensions[0].dimension]), 1).getTime() <
        step_end.getTime() &&
      addMinutes(new Date(d[query.timeDimensions[0].dimension]), 1).getTime() >=
        step_start.getTime(),
  );

  const time_frame_count = time_frame_values.reduce((count, d) => {
    if (!d[query.measures[0]]) {
      return count;
    }
    return count + parseFloat(d[query.measures[0]]);
  }, 0);

  const [priority_display_method, priority_display_values] =
    time_unit_configs.sampling.priority_display;
  const priority_display_value = priority_display_method(step_end);

  let y = time_frame_count;
  if (metric_type === 'rate') {
    y = time_frame_count / differenceInSeconds(step_end, step_start);
  } else if (metric_type === 'avg') {
    y = time_frame_count / time_frame_values.length;
  } else if (metric_type === 'percent') {
    y = time_frame_count / time_unit_configs.sampling.value;
  } else if (metric_type === 'max') {
    const values = time_frame_values.map((d) => parseFloat(d[query.measures[0]]));
    y = values.length === 0 ? 0 : Math.max(...values);
  }

  obj.data.push({
    priority_display: priority_display_values.some((value) => value === priority_display_value),
    x: format(step_end, time_unit_configs.sampling.date_format),
    y: y,
  });

  if (!isNaN(y)) {
    obj.total += y;
    obj.highest = Math.max(obj.highest, y);
    obj.average = obj.total / obj.data.filter((d) => !isNaN(d.y)).length;
  }

  obj.time_unit_configs = time_unit_configs;

  return obj;
};

export const useMetric = <
  T extends Record<string, ChartData> | ChartData | TableData[] | number | string | null,
>(
  type: MetricDisplayType,
  metric: MetricName,
  refresh_key: number,
  configs: MetricConfigs,
) => {
  const { options = {}, time_unit, date_range, force_rate_as } = configs;
  const { getCubeQueryParams, valueToNumber, type: metric_type } = metric_definitions[metric];
  const is_chart = type.includes('chart');

  const {
    start_date,
    end_date,
    configs: time_unit_configs,
    samples,
  } = useMemo(() => formatDateRange(date_range, time_unit), [date_range, time_unit, refresh_key]);

  const { query, dimensions } = useMemo(() => {
    const query_params = getCubeQueryParams(
      {
        ...options,
        filters: {
          ...options.filters,
          date: {
            min: start_date.toISOString(),
            max: end_date.toISOString(),
          },
        } as any,
      },
      is_chart ? time_unit_configs.sampling.granularity : undefined,
    );

    return {
      query: formatCubeQuery(query_params.model, { ...query_params.params, limit: 50000 }),
      dimensions: query_params.params.dimentions,
    };
  }, [start_date, start_date, metric, type]);

  const { raw_data, refetch, is_loading, error } = useCubeQueryLocalRawData<{
    [key: string]: string;
  }>(query, {
    resetResultSetOnChange: true,
  });

  useEffect(() => {
    if (!is_loading) {
      refetch();
    }
  }, [refresh_key]);

  const [data, rate_as] = useMemo(() => {
    if (!raw_data) {
      return [null, null];
    }
    let rate_as = force_rate_as || null;

    if (type === 'card') {
      let value = raw_data.reduce((count, d) => {
        if (metric_type === 'max') {
          return parseFloat(d[query.measures[0]]);
        }
        let sample_value = parseFloat(d[query.measures[0]] || '0');

        if (metric_type === 'rate') {
          sample_value = sample_value / differenceInSeconds(end_date, start_date);
        }

        return count + sample_value;
      }, 0);

      if (typeof value === 'string') {
        return [value];
      }

      if (metric_type === 'rate') {
        if (!rate_as && value < RATE_MINUTE_THRESHOLD) {
          rate_as = value < RATE_MINUTE_THRESHOLD ? 'minute' : 'second';
        }

        if (rate_as === 'minute') {
          value = value * 60;
        }
      }

      return [value, rate_as];
    }
    if (type === 'table') {
      const dimension = query.dimensions[0];
      const measure = query.measures[0];
      let data: { resource_id: string; value: number }[] = [];
      for (const d of raw_data) {
        if (!d[measure]) {
          continue;
        }
        data.push({
          resource_id: d[dimension],
          value: valueToNumber(d[measure]),
        });
      }

      if (metric_type === 'rate') {
        if (!rate_as) {
          rate_as = !data.some((value) => value.value > RATE_MINUTE_THRESHOLD)
            ? 'minute'
            : 'second';
        }

        if (rate_as === 'minute') {
          data = data.map((d) => ({ ...d, value: d.value * 60 }));
        }
      }
      return [data.sort((a: any, b: any) => b.value - a.value), rate_as];
    }
    const dimension = dimensions && dimensions[0];
    if (is_chart && dimension) {
      const data_by_dimension = raw_data.reduce(
        (obj, d) => {
          const id = d[query.dimensions[0]];
          if (obj[id]) {
            obj[id] = [...obj[id], d];
            return obj;
          }
          obj[id] = [d];
          return obj;
        },
        {} as Record<
          string,
          {
            [key: string]: string;
          }[]
        >,
      );

      const values = Object.entries(data_by_dimension).reduce(
        (obj, [key, values]) => {
          Array.from(Array(samples).keys()).reduce(
            (inner_obj, index) => {
              inner_obj = formatChartRawData(
                inner_obj,
                end_date,
                time_unit_configs,
                samples,
                values,
                query,
                index,
                metric_type,
              );

              obj[key] = inner_obj;

              return inner_obj;
            },
            {
              data: [],
              time_unit_configs,
              total: 0,
              highest: 0,
              average: 0,
            },
          ) as ChartData;

          return obj;
        },
        {} as Record<string, ChartData>,
      );

      if (metric_type === 'rate') {
        if (
          !rate_as &&
          !Object.values(values).some((value) => value.average > RATE_MINUTE_THRESHOLD)
        ) {
          rate_as = !Object.values(values).some((value) => value.average > RATE_MINUTE_THRESHOLD)
            ? 'minute'
            : 'second';
        }

        if (rate_as === 'minute') {
          Object.keys(values).forEach((key) => {
            values[key] = {
              ...values[key],
              data: values[key].data.map((d) => ({ ...d, y: (d.y as number) * 60 })),
              total: values[key].total * 60,
              highest: values[key].highest * 60,
              average: values[key].average * 60,
            };
          });
        }
      }
      return [values, rate_as];
    }
    if (is_chart) {
      let values = Array.from(Array(samples).keys()).reduce(
        (obj, index) => {
          return formatChartRawData(
            obj,
            end_date,
            time_unit_configs,
            samples,
            raw_data,
            query,
            index,
            metric_type,
          );
        },
        {
          data: [],
          time_unit_configs,
          total: 0,
          highest: 0,
          average: 0,
        },
      ) as ChartData;

      if (metric_type === 'rate') {
        if (!rate_as) {
          rate_as = values.average < RATE_MINUTE_THRESHOLD ? 'minute' : 'second';
        }

        if (rate_as === 'minute') {
          values = {
            ...values,
            data: values.data.map((d) => ({ ...d, y: (d.y as number) * 60 })),
            total: values.total * 60,
            highest: values.highest * 60,
            average: values.average * 60,
          };
        }
      }

      return [values, rate_as];
    }
    return [];
  }, [raw_data, metric_type, metric]);

  return { data: is_loading ? undefined : (data as T), rate_as, refetch, error };
};
