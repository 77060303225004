import {
  getDate,
  getHours,
  getMinutes,
  getSeconds,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfSecond,
} from 'date-fns';

export type Granularity = 'second' | 'minute' | 'hour' | 'day';
export type ResolutionMatrix = [Granularity, number[], number][];
export type ChartRange = 'last_30_days' | 'last_7_days' | 'all_time' | 'current_filters';

export type Resolution = {
  granularity: Granularity;
  factor_to_seconds: number;
  unit: number;
  steps: number;
};

export const chart_resolutions: ResolutionMatrix = [
  ['second', [1, 2, 3, 5, 10, 15, 30], 1],
  ['minute', [1, 2, 3, 5, 10, 15, 30], 60],
  ['hour', [1, 2, 3, 4, 6, 8, 12], 60 * 60],
  ['day', [1, 2, 7], 60 * 60 * 24],
];

export const timebar_resolutions: ResolutionMatrix = [
  ['second', [1, 5, 10, 30], 1],
  ['minute', [1, 5, 10, 30], 60],
  ['hour', [1, 2, 4, 12], 60 * 60],
  ['day', [1, 3, 7], 60 * 60 * 24],
];

export const getBestResolution = (
  seconds: number,
  target_steps: number,
  resolution_matrix: ResolutionMatrix,
): {
  best: Resolution;
  higher: Resolution;
} => {
  const flattened_matrix = resolution_matrix.reduce(
    (arr, [granularity, units, factor_to_seconds]) => {
      return [...arr, ...units.map((unit) => ({ granularity, unit, factor_to_seconds }))];
    },
    [] as { granularity: Granularity; unit: number; factor_to_seconds: number }[],
  );

  const candidates: Resolution[] = [];
  let stop_after_next = false;
  for (const { granularity, unit, factor_to_seconds } of flattened_matrix) {
    const steps = Math.ceil(seconds / (unit * factor_to_seconds));
    candidates.push({
      granularity,
      unit,
      factor_to_seconds,
      steps: Math.abs(steps),
    });
    if (stop_after_next) {
      break;
    }
    if (steps < target_steps) {
      stop_after_next = true;
    }
  }

  let current_best_index = 0;
  candidates.forEach(({ steps }, index) => {
    if (
      Math.abs(target_steps - steps) < Math.abs(target_steps - candidates[current_best_index].steps)
    ) {
      current_best_index = index;
    }
  });

  return {
    best: candidates[current_best_index],
    higher: candidates[current_best_index + 1] || candidates[current_best_index],
  };
};

export const startOfTime = {
  second: startOfSecond,
  minute: startOfHour,
  hour: startOfDay,
  day: startOfMonth,
};

export const getTime = {
  second: getSeconds,
  minute: getMinutes,
  hour: getHours,
  day: getDate,
};
