import { useContext } from 'react';
import { storage_keys } from '../../utils/storage';
import useLocalStorage from './useLocalStorage';
import { GlobalContext } from '../contexts/GlobalContext';
import { differenceInHours } from 'date-fns';
import useSWR from 'swr';

export function useDegradedBannerDismissedAt() {
  return useLocalStorage<string | null>(`${storage_keys.degraded_banner_dismissed_at}`, null);
}

export function useIsDegradedBanner() {
  const { HookdeckAPI } = useContext(GlobalContext);
  const [dismissed_at] = useDegradedBannerDismissedAt();
  const dismissed_recently =
    dismissed_at && differenceInHours(new Date(), new Date(dismissed_at)) < 24;
  const { data: status } = useSWR('status', () => HookdeckAPI.status.getServiceStatus());

  return status?.status === 'degraded' && !dismissed_recently;
}
