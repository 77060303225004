import cubejs, { CubejsApi } from '@cubejs-client/core';
import { decode } from 'jsonwebtoken';
import { useContext, useMemo } from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { DashboardContext } from '../scenes/DashboardScene/DashboardContext';
import useLocalStorage from './useLocalStorage';

const checkNotExpired = (token: string): boolean => {
  const decoded = decode(token);
  const current_date = Math.floor(new Date().getTime() / 1000);

  if (decoded === null) {
    return false;
  }

  if (typeof decoded === 'string') {
    return false;
  }

  return decoded.exp !== undefined && current_date < decoded.exp;
};

const useCubeApi = (): CubejsApi => {
  const { team } = useContext(DashboardContext);
  const [token, updateToken] = useLocalStorage<string | null>(
    `cubejs_token:${team?.id}`,
    null,
    true,
  );
  const { HookdeckAPI } = useContext(GlobalContext);

  const client = useMemo(
    () =>
      cubejs(
        async (): Promise<string> => {
          if (token && checkNotExpired(token)) {
            return Promise.resolve(token);
          }
          return HookdeckAPI.stats.token().then(({ token }) => {
            updateToken(token);
            return token;
          });
        },
        { apiUrl: process.env.CUBEJS_URL! },
      ),
    [],
  );

  return client;
};

export default useCubeApi;
