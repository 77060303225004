import { add, format, startOfHour } from 'date-fns';

export const hasSeconds = (date: Date | string) => {
  const [, , s] = format(new Date(date), 'HH:mm:ss').split(':');
  if (s && s !== '00') {
    return true;
  }
  return false;
};

export const hasMinutes = (date: Date | string) => {
  const [, m] = format(new Date(date), 'HH:mm:ss').split(':');
  if (m && m !== '00') {
    return true;
  }
  return false;
};

export const roundUpHour = (date: Date) => {
  if (hasSeconds(date) || hasMinutes(date)) {
    return startOfHour(add(date, { hours: 1 }));
  }
  return date;
};
