import { Form, Formik } from 'formik';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import { APIList } from '../../../../../../typings/API.interface';
import {
  View,
  ViewCreateInput,
  ViewFilters,
  ViewType,
  ViewUpdateInput,
} from '../../../../../../typings/View.interface';
import APIMethodKeys from '../../../client/APIMethodKeys';
import field_formats from '../../../utils/field-formatters';
import isEmpty from '../../../utils/isEmpty';
import { useDialog } from '../../common/Dialog';
import TextInput from '../../common/Form/Fields/TextInput';
import Modal from '../../common/Modal';
import { useToasts } from '../../common/Toast';
import { GlobalContext } from '../../contexts/GlobalContext';

export type IViewsContext = {
  views: APIList<View>;
  createView: (filters: object) => void;
  updateView: (id: string, filters: object) => void;
  renameView: (id: string) => void;
  deleteView: (id: string) => Promise<boolean>;
  duplicateView: (id: string) => Promise<View | undefined>;
};

export const ViewsContext = createContext<IViewsContext>({
  views: { models: [], pagination: { limit: 0 }, count: 0 },
  createView: () => {
    return;
  },
  updateView: () => {
    return;
  },
  renameView: () => {
    return;
  },
  deleteView: () => Promise.resolve(false),
  duplicateView: () => Promise.resolve(undefined),
});

export const ViewsContextProvider: React.FC<{
  type: ViewType;
  redirect_root?: string;
  children: React.ReactNode | ((context: IViewsContext) => React.ReactNode);
}> = ({ type, redirect_root, children }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { data: views, mutate } = useSWR(APIMethodKeys.views.list({ type }), () =>
    HookdeckAPI.views.list<View>({ type }),
  );

  const history = useHistory();
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const [action, setAction] = useState<{
    action: 'create' | 'update';
    view?: View;
    filters?: ViewFilters;
  } | null>(null);

  const handleRedirect = useCallback(
    (view?: View) => {
      if (redirect_root) {
        history.push(
          !view ? redirect_root : `${redirect_root}/${view.name.replace(/ /g, '-').toLowerCase()}`,
        );
      }
    },
    [redirect_root],
  );

  const mutateViews = useCallback(
    (mutate_type: 'create' | 'update' | 'delete', new_view: View) => {
      if (!views) return;
      const new_views: APIList<View> = { ...views };
      const index = new_views.models.findIndex((view) => view.id === new_view.id);
      switch (mutate_type) {
        case 'create':
          new_views.models = [new_view, ...new_views.models];
          new_views.count++;
          mutate(new_views);
          break;
        case 'update':
          new_views.models[index] = new_view;
          mutate(new_views);
          break;
        case 'delete':
          new_views.models.splice(index, 1);
          new_views.count--;
          mutate(new_views);
          break;
        default:
          return;
      }

      handleRedirect(mutate_type === 'delete' ? undefined : new_view);
    },
    [views, handleRedirect],
  );

  const createView = useCallback(
    (filters: ViewFilters): void => {
      setAction({ action: 'create', filters });
    },
    [views],
  );

  const createViewCallback = useCallback(
    async (name: string, filters: ViewFilters) => {
      return HookdeckAPI.views
        .create({ type, filters, name } as ViewCreateInput)
        .then((new_view) => {
          mutateViews('create', new_view);
          setAction(null);
          addToast('success', 'View created');
          return new_view;
        })
        .catch(() => {
          addToast('error', 'View failed to create');
        });
    },
    [views, mutateViews],
  );

  const updateView = useCallback(
    async (id: string, filters: ViewFilters) => {
      return HookdeckAPI.views
        .update(id, { filters } as ViewUpdateInput)
        .then((new_view) => {
          mutateViews('update', new_view);
          setAction(null);
          addToast('success', 'View updated');
          return new_view;
        })
        .catch(() => {
          addToast('error', 'View failed to update');
        });
    },
    [views, mutateViews],
  );

  const renameView = useCallback(
    (id: string): void => {
      const view = views?.models.find((view) => view.id === id);
      setAction({ action: 'update', view });
    },
    [views],
  );

  const renameViewCallback = useCallback(
    (id: string, name: string) => {
      return HookdeckAPI.views
        .update(id, { name } as ViewUpdateInput)
        .then((new_view) => {
          mutateViews('update', new_view);
          setAction(null);
          addToast('success', 'View renamed');
          return new_view;
        })
        .catch(() => {
          addToast('error', 'View failed to rename');
        });
    },
    [views, mutateViews],
  );

  const deleteView = useCallback(
    async (id: string): Promise<boolean> => {
      const view = views?.models.find((view) => view.id === id);
      if (!view) return false;
      return new Promise((resolve, reject) => {
        showDialog(
          () => {
            HookdeckAPI.views
              .delete(id)
              .then(() => {
                mutateViews('delete', view);
                addToast('success', 'View deleted');
                resolve(true);
              })
              .catch((e) => {
                addToast('error', 'Failed to remove view');
                reject(e);
              });
          },
          () => resolve(false),
          {
            title: 'Delete this view',
            message: `Are you sure you want to delete "${view.name}" view?`,
            danger: true,
          },
        );
      });
    },
    [views, mutateViews],
  );

  const duplicateView = useCallback(
    async (id: string): Promise<View | undefined> => {
      const view = views?.models?.find((view) => view.id === id);
      if (!view || (views && views.count > 249)) return;
      const name = view.name.split(' Copy')[0];
      let count = 0;
      views?.models?.forEach((view) => {
        if (view.name.split(' Copy')[0] === name) {
          count++;
        }
      });
      const body = { ...view };
      body.name = `${name} Copy ${count < 2 ? '' : count}`;
      return HookdeckAPI.views
        .create(body)
        .then((new_view) => {
          mutateViews('create', new_view);
          setAction(null);
          handleRedirect(new_view);
          addToast('success', 'View created');
          return new_view;
        })
        .catch((e) => {
          addToast('error', 'View failed to create');
          throw e;
        });
    },
    [views],
  );

  const context = useMemo(
    () => ({
      views: views as APIList<View>,
      renameView,
      createView,
      updateView,
      duplicateView,
      deleteView,
    }),
    [views, renameView, createView, updateView, duplicateView, deleteView],
  );

  if (!views || !context.views) return null;

  return (
    <ViewsContext.Provider value={context}>
      {action && (
        <Formik
          initialValues={{ name: action.view?.name || '' }}
          validate={async (values) => {
            const errors: { name?: string } = {};
            const should_validate = !isEmpty(values.name) && values.name !== action.view?.name;
            if (should_validate) {
              const views = await HookdeckAPI.views.list({ name: values.name });
              if (views.count > 0 && (!action.view?.id || action.view?.id !== views.models[0].id)) {
                errors.name = 'View name already in use';
              }
            }
            return errors;
          }}
          onSubmit={(values) =>
            action?.action === 'update'
              ? renameViewCallback(action.view!.id, values.name)
              : createViewCallback(values.name, action.filters!)
          }>
          {({ handleSubmit }) => (
            <Form>
              <Modal
                portal
                title={action?.action === 'update' ? 'Rename this view' : 'Create a new view'}
                onClose={() => setAction(null)}
                submit_label={action?.action === 'update' ? 'Update' : 'Save'}
                cancel_label={'Cancel'}
                onSubmit={handleSubmit}
                onCancel={() => setAction(null)}>
                <TextInput format={field_formats.viewName} focus m={0} required name="name" />
              </Modal>
            </Form>
          )}
        </Formik>
      )}
      {typeof children === 'function' ? children(context) : children}
    </ViewsContext.Provider>
  );
};
