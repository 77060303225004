import { Formik } from 'formik';
import { useContext } from 'react';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useToasts } from '../../../../common/Toast';
import Modal from '../../../../common/Modal';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Team } from '../../../../../../../../typings/Team.interface';

const NewOrganizationModal: React.FC<{
  onClose: () => void;
  onSuccess: (team: Team) => void;
}> = ({ onClose, onSuccess }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();

  const onSubmit = (v) => {
    HookdeckAPI.organizations
      .create({ name: v.name })
      .then(({ default_team }) => {
        addToast('success', `Organization ${v.name} created`);
        onSuccess(default_team);
      })
      .catch(() => {
        addToast('error', `Failed to create the organization, please contact us.`);
      });
  };

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validate={(v) => {
        if (!v.name || v.name.length === 0) {
          return { name: 'Required' };
        }
        if (v.name && v.name.length > 40) {
          return { name: 'Name is longer then 40 characters' };
        }
      }}
      onSubmit={onSubmit}>
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Modal
            portal={true}
            title="Create new organization"
            cancel_label="Cancel"
            submit_label="Create Organization"
            submit_icon={props.isSubmitting ? 'loading' : 'add_circle'}
            submit_disabled={props.isSubmitting || !props.dirty || !props.isValid}
            onSubmit={props.handleSubmit}
            onCancel={onClose}
            onClose={onClose}>
            <TextInput name="name" label="Organization name" required m={0} />
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default NewOrganizationModal;
