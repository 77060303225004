import { intervalToDuration } from 'date-fns';

const getFormattedDuration = (milliseconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: milliseconds });
  const years = duration.years ? `${duration.years} yr` : '';
  const months = duration.months ? `${duration.months} mo` : '';
  const weeks = duration.weeks ? `${duration.weeks} wk` : '';
  const days = duration.days ? `${duration.days} d` : '';
  const hours = duration.hours ? `${duration.hours} hr` : '';
  const minutes = duration.minutes ? `${duration.minutes} min` : '';
  const seconds = duration.seconds ? `${duration.seconds} sec` : '';

  return `${years} ${months} ${weeks} ${days} ${hours} ${minutes} ${seconds}`;
};

export default getFormattedDuration;
