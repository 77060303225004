import { Fragment, useContext, useState } from 'react';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import HookdeckAPI from '../../../../client/Hookdeck';
import { ClickableArea } from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Text from '../../../common/base/Text';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import Search from '../../../common/Search';
import { StyledNavLinks } from '.';
import { GlobalContext } from '../../../contexts/GlobalContext';
import Loading from '../../../common/base/Loading';
import { useLocation } from 'react-router';

const resources_map = {
  sources: {
    label: 'Sources',
    path: '/sources',
    getSWRProps: (HookdeckAPI: HookdeckAPI, search: string) => ({
      key: APIMethodKeys.sources.list({
        name: search ? { contains: search } : undefined,
        limit: 250,
      }),
      fetcher: () =>
        HookdeckAPI.sources.list({
          name: search ? { contains: search } : undefined,
          limit: 250,
        }) as any,
    }),
  },
  destinations: {
    label: 'Destinations',
    path: '/destinations',
    getSWRProps: (HookdeckAPI: HookdeckAPI, search: string) => ({
      key: APIMethodKeys.destinations.list({
        name: search ? { contains: search } : undefined,
        limit: 250,
      }),
      fetcher: () =>
        HookdeckAPI.destinations.list({
          name: search ? { contains: search } : undefined,
          limit: 250,
        }),
    }),
  },
  transformations: {
    label: 'Transformations',
    path: '/transformations',
    getSWRProps: (HookdeckAPI: HookdeckAPI, search: string) => ({
      key: APIMethodKeys.transformations.list({
        name: search ? { contains: search } : undefined,
        limit: 250,
      }),
      fetcher: () =>
        HookdeckAPI.transformations.list({
          name: search ? { contains: search } : undefined,
          limit: 250,
        }),
    }),
  },
};

const ResourceDropdownContent: React.FC<{
  resource: keyof typeof resources_map;
  toggle: (opened: boolean) => void;
}> = ({ resource, toggle }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { label, path, getSWRProps } = resources_map[resource];
  const [search, setSearch] = useState('');
  const { key, fetcher } = getSWRProps(HookdeckAPI, search);
  const { data: response } = useSWR(key, fetcher);

  return (
    <>
      <StyledCardSection muted p={{ x: 3, y: 1 }}>
        <Text size="xs" subtitle>
          {label}
        </Text>
      </StyledCardSection>
      <StyledCardSection p={{ x: 3, y: 2 }}>
        <Search focus small value={search} onChange={setSearch} />
      </StyledCardSection>
      <StyledCardSection max_h={{ px: 204 }} scroll={!!response}>
        <Loading require={[response]} wrapper={(loading) => <Div m={{ y: 3 }}>{loading}</Div>}>
          {() => (
            <>
              {response!.count === 0 && !search && (
                <Text muted p={{ x: 3, y: 2 }}>
                  No {label.toLowerCase()} yet....
                </Text>
              )}
              {response!.count === 0 && search && (
                <Text muted p={{ x: 3, y: 2 }}>
                  No matching {label.toLowerCase()}...
                </Text>
              )}
              {response!.models.map((model, i) => (
                <Fragment key={model.id}>
                  {i !== 0 && <Divider />}
                  <ClickableArea
                    w={100}
                    p={{ x: 3, y: 2 }}
                    onClick={() => toggle(false)}
                    to={`${path}/${model.id}`}>
                    <Text monospace size="m" ellipsis>
                      {model.name}
                    </Text>
                  </ClickableArea>
                </Fragment>
              ))}
              {response!.pagination.next && (
                <>
                  <Divider />
                  <Text muted p={{ x: 3, y: 2 }}>
                    Search to see more {label.toLowerCase()}...
                  </Text>
                </>
              )}
            </>
          )}
        </Loading>
      </StyledCardSection>
    </>
  );
};

const ResourceLink: React.FC<{ resource: keyof typeof resources_map }> = ({ resource }) => {
  const { label, path } = resources_map[resource];
  const location = useLocation();

  return (
    <Dropdown
      placement="right-start"
      block
      p={0}
      m={{ l: 2 }}
      parent_width={{ min: 248, max: 248 }}
      renderToggle={(opened, toggle) => (
        <StyledNavLinks>
          <ClickableArea
            on="background"
            highlight={opened || location.pathname.indexOf(path) == 0}
            onClick={() => toggle(!opened)}>
            <Div flex={{ align: 'center', justify: 'space-between' }}>
              <Text muted subtitle size="m" ellipsis>
                {label}
              </Text>
            </Div>
          </ClickableArea>
        </StyledNavLinks>
      )}>
      {(toggle) => <ResourceDropdownContent resource={resource} toggle={toggle} />}
    </Dropdown>
  );
};

export default ResourceLink;
