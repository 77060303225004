export const formatPath = (path: string, options?: { escape_parentheses: boolean }) => {
  const { escape_parentheses = true } = options || {};

  path = path.replace(/ /g, '-');

  if (escape_parentheses) {
    path = path.replace(/[(]/g, '\\(');
    path = path.replace(/[)]/g, '\\)');
  }

  return path.toLowerCase();
};
