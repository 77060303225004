import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { SelectButton } from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import DropdownMenu from '../../../common/DropdownMenu';
import { Div } from '../../../common/helpers/StyledUtils';
import { DashboardContext, View } from '../DashboardContext';

export const views = {
  http: {
    label: 'HTTP View',
    icon: 'http' as const,
    links: [
      {
        key: 'requests',
        path: `/requests`,
        label: 'Requests',
        icon: 'requests' as const,
      },
      {
        key: 'events',
        path: `/events`,
        label: 'Events',
        icon: 'events' as const,
      },
      {
        key: 'bookmarks',
        path: `/bookmarks`,
        label: 'Bookmarks',
        icon: 'bookmarks' as const,
      },
    ],
  },
  cli: {
    label: 'CLI View',
    icon: 'terminal' as const,
    links: [
      {
        key: 'requests',
        path: `/cli/requests`,
        label: 'Requests',
        icon: 'requests' as const,
      },
      {
        key: 'events',
        path: `/cli/events`,
        label: 'Events',
        icon: 'events' as const,
      },
      {
        key: 'bookmarks',
        path: `/cli/bookmarks`,
        label: 'Bookmarks',
        icon: 'bookmarks' as const,
      },
    ],
  },
};

const getViewByUrl = (url: string) => {
  return (Object.keys(views) as View[]).find((view) =>
    views[view].links.find((link) => url.startsWith(link.path)),
  );
};

const StyledSelectButtonWrapper = styled(Div)`
  span {
    color: ${({ theme }) => theme.colors.on.neutral.secondary_neutral};
  }
`;
export default () => {
  const { view: context_view, updateView, active_cli_sessions } = useContext(DashboardContext);
  const history = useHistory();
  const view_by_url = getViewByUrl(location.pathname);
  const view = view_by_url ?? context_view;

  const current_view = views[view_by_url ?? context_view];

  useEffect(() => {
    updateView(view);
  }, [view, updateView]);

  return (
    <DropdownMenu
      p={0}
      parent_width
      label={current_view.label}
      renderToggle={(opened, toggle) => (
        <>
          <StyledSelectButtonWrapper>
            <SelectButton
              onClick={() => toggle(!opened)}
              icon={current_view.icon}
              block
              m={{ y: 1 }}
              p={{ x: 2 }}>
              {current_view.label}
            </SelectButton>
          </StyledSelectButtonWrapper>
          {view === 'cli' && active_cli_sessions?.length === 0 && (
            <Div flex={{ align: 'center' }} m={{ x: 2, t: 1, b: 2 }}>
              <Icon danger icon="error" left />
              <Text danger>CLI Disconnected</Text>
            </Div>
          )}
        </>
      )}
      title="Select View"
      options={Object.entries(views).map(([key, view_config]) => ({
        label: view_config.label,
        icon: view_config.icon,
        selected: view === key,
        onClick: () => {
          const match = current_view.links.find((l) => location.pathname.startsWith(l.path));
          const equivalent = match && views[key].links.find((l) => l.key === match.key);
          if (match) {
            history.push(
              location.pathname.replace(match.path, equivalent.path) || view_config.links[0].path,
            );
          } else {
            updateView(key as 'cli' | 'http');
          }
        },
      }))}
    />
  );
};
