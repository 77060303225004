import { subHours } from 'date-fns';
import LogRocket from 'logrocket';
import Script from 'next/script';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import HookdeckAPI from '../../../../client/Hookdeck';
import LINKS from '../../../../configs/links';
import { capitalizeFirstLetter } from '../../../../utils';
import { showChat } from '../../../../utils/liveChat';
import { isFreePlan } from '../../../../utils/subscription';
import Avatar from '../../../common/base/Avatar';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import TextSwitch from '../../../common/base/TextSwitch';
import LineChart from '../../../common/Chart/LineChart';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import { ChartData, useMetric } from '../../../common/metrics/useMetric';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { UserContext } from '../../../contexts/UserContext';
import { DashboardContext } from '../DashboardContext';
import { StyledDashboardNavSection, StyledNavLinks, StyledPrimaryNavLink } from './';

export const handleLogout = (HookdeckAPI: HookdeckAPI) => {
  return HookdeckAPI.session.logout().then((response) => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (response?.redirect_url) {
      window.location.replace(response?.redirect_url);
    } else {
      window.location.replace('/signin');
    }
  });
};

export const StyledBeamerButton = styled(ClickableArea)<{ opened?: boolean }>(
  ({ theme, opened }) => css`
    border: none;

    ${opened &&
    css`
      background-color: ${theme.colors.surface.base.variant_surface};
    `}

    &:hover {
      opacity: 1 !important;
    }
    &:focus-visible {
      &:after {
        content: none;
      }
    }
    .beamer_icon.active {
      box-sizing: border-box;
      font-family: inherit;
      position: relative;
      line-height: unset;
      text-transform: capitalize;
      border-radius: ${theme.radius.small};
      font-weight: ${theme.font_weigths.medium};
      width: unset;
      min-width: ${theme.pxToRem(20)};
      height: ${theme.pxToRem(20)};
      line-height: ${theme.pxToRem(16)};
      font-size: ${theme.pxToRem(12)};
      font-weight: ${theme.font_weigths.medium};
      padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
      margin-left: auto;
      color: ${theme.colors.on.hue_container.primary};
      background-color: ${theme.colors.surface.container.primary} !important;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${theme.radius.small};
        border: 1px solid ${theme.colors.outline.primary};
      }
    }
  `,
);

const NavFooter = () => {
  const { user } = useContext(UserContext);
  const { subscription, organization, team, show_get_started, setShowGetStarted } =
    useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);

  const theme = useTheme();
  const history = useHistory();

  const max_events = useMetric<ChartData>('chart:line', 'events_max_rate', 0, {
    date_range: {
      min: subHours(new Date(), 6).toISOString(),
      max: new Date().toISOString(),
    },
  });

  let throughput_status: 'ok' | 'warn' | 'danger' | null = null;
  if (max_events.data && max_events.data.data) {
    const max_event = Math.max(...max_events.data.data.map((d) => d.y as number));
    if (
      team!.max_events_per_second <
      (max_events.data.data[max_events.data.data.length - 1].y as number)
    ) {
      throughput_status = 'danger';
    } else if (max_event > team!.max_events_per_second) {
      throughput_status = 'warn';
    } else {
      throughput_status = 'ok';
    }
  }

  return (
    <StyledDashboardNavSection p={{ x: 2, t: 4, b: 2 }}>
      <StyledNavLinks>
        {subscription?.plan &&
          isFreePlan(subscription.plan) &&
          !organization?.workos_directory_id && (
            <StyledPrimaryNavLink to={`/settings/organization/plans`}>
              <Icon left icon="upgrade" />
              <Text subtitle size="m">
                Upgrade Plan
              </Text>
            </StyledPrimaryNavLink>
          )}
        <NavLink to="/settings">
          <Icon left icon="settings" />
          <Text subtitle size="m">
            Settings
          </Text>
        </NavLink>
        <Dropdown
          placement="right-end"
          block
          p={0}
          m={{ l: 2 }}
          onToggle={(opened) => {
            if (opened) {
              LogRocket.track('Clicked on Project Throughput', {
                throughput_status: throughput_status || undefined,
              });
              HookdeckAPI.track.event('Clicked on Project Throughput', { throughput_status });
            }
          }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <ClickableArea
                highlight={opened}
                on="background"
                flex={{ align: 'center' }}
                onClick={() => toggle(!opened)}>
                <Div style={{ position: 'relative' }}>
                  <Icon icon="throughput" muted />
                </Div>
                <Text
                  m={{ l: 2 }}
                  subtitle
                  size="m"
                  w={100}
                  flex={{ justify: 'space-between', align: 'center' }}>
                  Project Throughput
                </Text>
                <div>
                  {throughput_status === 'warn' && <Icon icon="warning" warning />}
                  {throughput_status === 'danger' && <Icon icon="error" danger />}
                </div>
              </ClickableArea>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <StyledCardSection w={{ px: 480 }}>
                <StyledCardSection p={4} flex={{ gap: 2 }}>
                  <Text>
                    {throughput_status === 'ok' &&
                      'Your project is processing new events within your throughput threshold.'}
                    {throughput_status === 'warn' &&
                      'Your project has exceeded the throughput of new events within the last 6 hours.'}
                    {throughput_status === 'danger' &&
                      'Your project is currently exceededing the throughput of new events'}
                  </Text>
                </StyledCardSection>
                <LineChart
                  compact
                  height={168}
                  loading={!max_events}
                  datasets={
                    max_events && max_events.data
                      ? [
                          {
                            key: 'delivered',
                            label: 'Max new events per second',
                            theme_color: 'primary' as const,
                            monospace: false,
                            data: max_events.data.data,
                            total: max_events.data.total,
                            highest: max_events.data.highest,
                            average: max_events.data.average,
                            getDataLabel: (v: number) => `${v} / second`,
                            metric_type: 'max' as const,
                          },
                          {
                            key: 'limit',
                            label: 'Throughput limit',
                            theme_color: 'danger' as const,
                            monospace: false,
                            dashed: true,
                            data: max_events.data.data.map((d) => ({
                              ...d,
                              y: team!.max_events_per_second,
                            })),
                            // Placeholder values not actually used for anything
                            total: 0,
                            highest: 0,
                            average: 0,
                            getDataLabel: (v: number) => `${v} / second`,
                            metric_type: 'rate' as const,
                          },
                        ]
                      : []
                  }
                />
              </StyledCardSection>
              <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'flex-end' }}>
                <Button
                  outline
                  icon="link"
                  to="/settings/project/quotas"
                  onClick={() => toggle(false)}>
                  View Throughput
                </Button>
              </StyledCardSection>
            </>
          )}
        </Dropdown>
        {process.env.BEAMER_ID && (
          <>
            <Script>
              {`var beamer_config = {
            product_id: "${process.env.BEAMER_ID}",
            button: false,
            bounce: false,
            display: "popup",
            lazy: false,
            user_id: "${user?.id}",
            user_email: "${user?.email}",
            user_created_at: "${user?.created_at}",
            user_firstname: "${user?.name}",
          };`}
            </Script>
            <Script src="https://app.getbeamer.com/js/beamer-embed.js" />
          </>
        )}
        <Dropdown
          placement="right-end"
          block
          p={0}
          m={{ l: 2 }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <StyledBeamerButton
                opened={opened}
                flex={{ align: 'center' }}
                className="beamerTrigger"
                data-beamer-click="false"
                onClick={() => toggle(!opened)}>
                <Icon left icon="help" />
                <Text subtitle size="m">
                  Docs & Support
                </Text>
              </StyledBeamerButton>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <StyledCardSection p={{ x: 1, y: 2 }}>
                {!show_get_started && (
                  <ClickableArea
                    p={{ x: 2, y: 1 }}
                    flex={{ align: 'center' }}
                    rounded
                    onClick={() => {
                      history.push('/get-started');
                      setShowGetStarted(true);
                      toggle(false);
                    }}>
                    <Icon icon="grid_view" left dark />
                    <Text subtitle size="m">
                      Get Started
                    </Text>
                  </ClickableArea>
                )}
                <ClickableArea
                  p={{ x: 2, y: 1 }}
                  as="a"
                  href={LINKS.docs}
                  target="_blank"
                  rel="noreferrer"
                  flex={{ align: 'center' }}
                  rounded>
                  <Icon icon="docs" left dark />
                  <Text subtitle size="m">
                    Documentation
                  </Text>
                </ClickableArea>
                <ClickableArea
                  p={{ x: 2, y: 1 }}
                  as="a"
                  href={LINKS.api_ref.root}
                  target="_blank"
                  rel="noreferrer"
                  flex={{ align: 'center' }}
                  rounded>
                  <Icon icon="api" left dark />
                  <Text subtitle size="m">
                    API Reference
                  </Text>
                </ClickableArea>
                <StyledBeamerButton
                  p={{ x: 2, y: 1 }}
                  flex={{ align: 'center' }}
                  rounded
                  className="beamerTrigger">
                  <Icon icon="new_releases" left />
                  <Text subtitle size="m">
                    What's new
                  </Text>
                </StyledBeamerButton>
              </StyledCardSection>
              <StyledCardSection p={{ x: 1, y: 2 }} min_w={{ px: 200 }}>
                <ClickableArea
                  p={{ x: 2, y: 1 }}
                  flex={{ align: 'center' }}
                  rounded
                  onClick={showChat}>
                  <Icon left icon="chat" />
                  <Text subtitle size="m">
                    Live Chat
                  </Text>
                </ClickableArea>
                <ClickableArea
                  p={{ x: 2, y: 1 }}
                  flex={{ align: 'center' }}
                  rounded
                  as="a"
                  href="mailto:info@hookdeck.com"
                  target="_blank"
                  rel="noreferrer">
                  <Icon icon="email" left />
                  <Text subtitle size="m">
                    Email us
                  </Text>
                </ClickableArea>
                <ClickableArea
                  p={{ x: 2, y: 1 }}
                  flex={{ align: 'center' }}
                  rounded
                  as="a"
                  href={LINKS.community}
                  target="_blank"
                  rel="noreferrer">
                  <Icon icon="slack_colored" left />
                  <Text subtitle size="m">
                    Join Slack
                  </Text>
                </ClickableArea>
              </StyledCardSection>
            </>
          )}
        </Dropdown>
        <Dropdown
          placement="right-end"
          p={0}
          m={{ l: 2 }}
          parent_width={{ min: 225 }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <ClickableArea
                highlight={opened}
                on="background"
                flex={{ align: 'center' }}
                onClick={() => toggle(!opened)}>
                <Avatar name={user!.name} small neutral m={{ r: 2 }} />
                <Text subtitle ellipsis>
                  {capitalizeFirstLetter(user!.name)}
                </Text>
              </ClickableArea>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <Div p={3}>
                <Text ellipsis subtitle>
                  {user!.name}
                </Text>
                <Text ellipsis subtitle muted>
                  {user!.email}
                </Text>
              </Div>
              <Divider />
              <Div p={1}>
                <ClickableArea
                  to="/settings/account/profile"
                  onClick={() => toggle(false)}
                  p={{ y: 1.5, x: 2 }}
                  rounded
                  flex={{ align: 'center' }}>
                  <Icon icon="settings" left />
                  <Text subtitle>Account Settings</Text>
                </ClickableArea>
                <ClickableArea
                  onClick={() => {
                    toggle(false);
                    handleLogout(HookdeckAPI);
                  }}
                  p={{ y: 1.5, x: 2 }}
                  m={{ t: 1 }}
                  rounded
                  flex={{ align: 'center' }}>
                  <Icon icon={'logout'} left={2} />
                  <Text subtitle>Logout</Text>
                </ClickableArea>
              </Div>
              <Divider />
              <Div p={1}>
                <Div flex={{ align: 'center', justify: 'space-between' }} p={2}>
                  <Div w={100} flex={{ align: 'center', justify: 'space-between' }}>
                    <Div flex={{ align: 'center' }}>
                      <Icon
                        icon={
                          theme.selected === 'system'
                            ? 'computer'
                            : theme.selected === 'light'
                              ? 'light_mode'
                              : 'dark_mode'
                        }
                        left
                      />
                      <Text subtitle>Theme</Text>
                    </Div>
                    <TextSwitch
                      options={[
                        {
                          label: <Icon title="System" icon="computer" />,
                          key: 'system',
                        },
                        { label: <Icon title="Light" icon="light_mode" />, key: 'light' },
                        { label: <Icon title="Dark" icon="dark_mode" />, key: 'dark' },
                      ]}
                      active={theme.selected}
                      onSelect={(key) => theme.toggleTheme(key as 'light' | 'dark')}
                    />
                  </Div>
                </Div>
              </Div>
            </>
          )}
        </Dropdown>
      </StyledNavLinks>
    </StyledDashboardNavSection>
  );
};

export default NavFooter;
